import styled from "@emotion/styled";

export type FlexDirectionT = "row" | "column" | "row-reverse";
export type FlexWrapT = "nowrap" | "wrap";
export type AlignT =
  | "stretch"
  | "center"
  | "flex-start"
  | "space-around"
  | "flex-end"
  | "space-between"
  | "auto";

type FlexBoxT = {
  flexDirection?: FlexDirectionT;
  justifyContent?: AlignT;
  alignItems?: AlignT;
  alignSelf?: AlignT;
  flexWrap?: FlexWrapT;
  title?: string;
  fullHeight?: boolean;
  fullWidth?: boolean;
  className?: string;
};

const FlexBox = styled.div<FlexBoxT>(
  ({
    flexDirection = "row",
    justifyContent = "center",
    alignItems = "center",
    alignSelf = "auto",
    flexWrap = "nowrap",
    fullHeight = false,
    fullWidth = false,
    className,
  }) => ({
    display: "flex",
    flexDirection,
    justifyContent,
    alignItems,
    alignSelf,
    flexWrap,
    height: fullHeight ? "100%" : "auto",
    width: fullWidth ? "100%" : "auto",
  })
);

export default FlexBox;
