import styled from "@emotion/styled";

export const Overlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: "rgba(2, 2, 2, .8)",
  zIndex: -1,
});
