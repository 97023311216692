export type MediaT = {
  url: string;
};

type RenderT = {
  desktop: DataT<MediaT>;
  mobile: DataT<MediaT | null>;
};

type ImageLayerT = {
  image: DataT<MediaT>;
  image_mobile: DataT<MediaT | null>;
};

type SectionOptionsT = {
  background_color: string;
  full_height: boolean;
};

export type MediaAlignT = "left" | "right" | "center";

export type MenuItemComponentT = {
  id: number;
  source: string;
  label: string;
};

export type GridItemComponentT = {
  id: number;
  x_cor: number;
  y_cor: number;
  z_cor: number;
  media: DataT<MediaT>;
  description: string;
};

export type TextBlockComponentT = {
  id: number;
  text: string;
  text_color: string;
  highlight_color: string;
  width: number;
  x_offset: number;
  y_offset?: number;
  paragraph_size?: number;
};

export type ContentTypeT =
  | "media_single"
  | "media_grid"
  | "media_description"
  | "text_blocks"
  | "empty";

export type ContentComponentT = {
  __component:
    | "molecule.content-loop"
    | "molecule.content-render"
    | "molecule.content-vimeo"
    | "molecule.content-text";
  options: SectionOptionsT;
};

export type SectionLoopT = {
  __component: "molecule.content-loop";
  options: SectionOptionsT;
  align: MediaAlignT;
  width: number;
  media: DataT<MediaT>;
  text: TextBlockComponentT | null;
};

export type SectionRenderT = {
  __component: "molecule.content-render";
  options: SectionOptionsT;
  image_layer: ImageLayerT | null;
  render: RenderT | null;
  text: TextBlockComponentT[] | null;
};

export type SectionTextT = {
  __component: "molecule.content-text";
  options: SectionOptionsT;
  text_blocks: TextBlockComponentT[];
};

export type SectionVimeoT = {
  __component: "molecule.content-vimeo";
  options: SectionOptionsT;
  vimeo_url: string;
};

export type SectionGridT = {
  __component: "molecule.content-grid-media";
  options: SectionOptionsT;
  items: GridItemComponentT[];
};

export type PortfolioCategoryT = {
  navigation_id: string;
  portfolios: CollectionDataT<SectionT>;
  render: RenderT;
  text: TextBlockComponentT | null;
};

export type PortfolioCategoryStriperT = {
  navigation_id: string;
  render: RenderT;
  text: TextBlockComponentT | null;
};

export type SectionT = {
  order: number;
  slug: string;
  subtitle: string;
  title: string;
  cover: RenderT;
  content_blocks: Array<
    SectionLoopT | SectionRenderT | SectionTextT | SectionVimeoT | SectionGridT
  >;
};

export type ContactT = {
  title: string;
  text_blocks: TextBlockComponentT[];
  render: RenderT;
};

export type VisionT = {
  content: TextBlockComponentT;
  render: RenderT;
};

export type IntroT = {
  render: RenderT;
};

export type SwcT = {
  text: TextBlockComponentT;
  cover: RenderT;
};

export type ConceptsT = {
  intro: TextBlockComponentT;
  cover: RenderT;
  items: GridItemComponentT[];
  height: number;
  background_color: string;
};

export type NavigationT = {
  items: MenuItemComponentT[];
};

type Attributes<T> = {
  attributes: T;
};

export type CollectionAttributes<T> = {
  id: number;
  attributes: T;
};

export type DataT<T> = {
  data: Attributes<T>;
};

export type CollectionDataT<T> = {
  data: CollectionAttributes<T>[];
  meta: string;
};

export enum MediaFileTypeE {
  image = "image",
  video = "video",
}
