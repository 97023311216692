import { createContext, Dispatch, FC, SetStateAction } from "react";
import { LightBoxMedia } from "../features/LightBox";

type AppHelperContextT = {
  setLightBoxState: Dispatch<SetStateAction<LightBoxMedia[] | undefined>>;
  activeSection: number | null;
  setActiveSection: Dispatch<SetStateAction<number | null>>;
};

export const AppHelperContext = createContext<AppHelperContextT>({
  setLightBoxState: () => {},
  activeSection: null,
  setActiveSection: () => {},
});

export const AppHelperContextProvider: FC<AppHelperContextT> = ({
  setLightBoxState,
  activeSection,
  setActiveSection,
  children,
}) => (
  <AppHelperContext.Provider
    value={{
      setLightBoxState,
      activeSection,
      setActiveSection,
    }}
  >
    {children}
  </AppHelperContext.Provider>
);
