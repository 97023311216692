import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import { getMediaFileType } from "../utils/helpers";
import { MediaFileTypeE } from "../utils/types";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)({
  maxHeight: "100vh",
  width: "100%",
  maxWidth: rem(1000),

  [theme.media.maxSm]: {
    width: "70%",
    maxWidth: rem(650),
  },
});

const StyledVideo = styled.video({
  width: "100%",
  margin: "auto",
  maxHeight: "100%",
  backgroundColor: "black",
});

const StyledImage = styled.img({
  width: "auto",
  margin: "auto",
  maxHeight: "90vh",
});

const Description = styled(FlexBox)({
  position: "absolute",
  bottom: rem(20),
  color: theme.color.whiteColor,
  fontSize: rem(16),
  fontWeight: 300,
  opacity: 0.7,
  marginTop: rem(10),
  textAlign: "left",
});

type LightBoxItemT = {
  url: string;
  description?: string;
};

const LightBoxItem: FC<LightBoxItemT> = ({ url, description }) => {
  const mediaType = getMediaFileType(url);

  return (
    <Wrapper alignItems="flex-start" flexDirection="column">
      {mediaType === MediaFileTypeE.video ? (
        <StyledVideo controls={true} width="100%" src={url} />
      ) : (
        <StyledImage src={url} />
      )}
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

export default LightBoxItem;
