import { DataT, MediaFileTypeE, MediaT } from "./types";

const IMAGE_EXTENSIONS = ["jpg", "jpeg", "png", "gif", "bmp", "heic"];
const VIDEO_EXTENSIONS = ["m4v", "mp4", "wmv", "flv", "avi", "mkv", "3gp"];

type getMediaFileTypeT = (url: string) => MediaFileTypeE | "not_recognize";
export const getMediaFileType: getMediaFileTypeT = (url) => {
  const regExp = /(\.[^.]*)(?=\?|$)/;
  const regExpResult = regExp.exec(url);

  const fileExtension = regExpResult && regExpResult[0].substring(1);

  if (!!IMAGE_EXTENSIONS.find((imgExt) => imgExt === fileExtension)) {
    return MediaFileTypeE.image;
  }

  if (!!VIDEO_EXTENSIONS.find((vidExt) => vidExt === fileExtension)) {
    return MediaFileTypeE.video;
  }

  return "not_recognize";
};

export const getCoverUrl = (
  breakpoint,
  desktop: DataT<MediaT>,
  mobile: DataT<MediaT | null>
): string => {
  const mobileCover = mobile && mobile.data && mobile.data.attributes?.url;
  const desktopCover = desktop.data.attributes.url;

  return breakpoint && mobileCover ? mobileCover : desktopCover;
};
