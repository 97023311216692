import styled from "@emotion/styled";
import { FC, useContext, useEffect } from "react";
import FlexBox from "../ui/FlexBox";
import LightBoxItem from "../ui/LightBoxItem";
import { Overlay } from "../ui/Overlay";
import { AppHelperContext } from "../utils/AppHelperProvider";

const Wrapper = styled(FlexBox)<{ visible: boolean }>(({ visible }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 5,
  overflow: "scroll",
  scrollbarWidth: "none",
  visibility: visible ? "visible" : "hidden",
  opacity: visible ? 1 : 0,
  transition: "opacity 0.4s, visibility 0.4s",
}));

export type LightBoxMedia = {
  url: string;
  description?: string;
};

type LightBoxT = {
  files?: LightBoxMedia[];
};

const LightBox: FC<LightBoxT> = ({ files }) => {
  const { setLightBoxState } = useContext(AppHelperContext);

  const close = () => {
    setLightBoxState(undefined);
  };

  type onKeyDownT = (e: KeyboardEvent) => void;
  const onKeyDown: onKeyDownT = (e) => {
    const { key } = e;

    if (key == "Escape") {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <Wrapper visible={!!files} justifyContent="center" alignItems="center">
      <Overlay onClick={close} />
      {files &&
        files.map((file, index) => (
          <LightBoxItem
            key={index}
            url={file.url}
            description={file.description}
          />
        ))}
    </Wrapper>
  );
};

export default LightBox;
