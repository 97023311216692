import styled from "@emotion/styled";
import "animate.css";
import React, { FC, useEffect, useState } from "react";
import BodyClassName from "react-body-classname";
import { Helmet } from "react-helmet";
import smoothscroll from "smoothscroll-polyfill";
import DesktopExperienceModal from "../features/DesktopExperienceModal";
import LightBox, { LightBoxMedia } from "../features/LightBox";
import { useResponsive } from "../hooks/useResponsive";
import { rem } from "../styling/theme";
import { AppHelperContextProvider } from "../utils/AppHelperProvider";

const PreventAutoSelectOnScrollWrapper = styled.div({
  paddingBottom: rem(1),
});

const PageWrapper: FC<{ dataIsLoading?: boolean }> = ({
  children,
  dataIsLoading,
}) => {
  const [desktopExperienceModal, setDesktopExperienceModal] = useState(false);
  const [viewScrolled, setViewScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [lightBoxState, setLightBoxState] = useState<
    LightBoxMedia[] | undefined
  >();

  const { mobileVersion, tabletVersion } = useResponsive();

  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }

  useEffect(() => {
    const cursor = document.getElementById("cursor");
    if (cursor) {
      document.addEventListener("mousemove", (e) => {
        cursor.style.top = e.pageY + "px";
        cursor.style.left = e.pageX + "px";
        cursor.style.opacity = "1";
      });
    }
  }, []);

  const scrollDisable =
    (activeSection && activeSection > -1) ||
    lightBoxState ||
    desktopExperienceModal;

  useEffect(() => {
    const { search } = location;
    const slug = search && search.includes("?") && search.substring(1);

    if (slug) {
      const section = document.getElementById(`${slug}`);

      if (section && !viewScrolled) {
        section.scrollIntoView({ behavior: "smooth" });
        setViewScrolled(true);
      }
    }
  }, [dataIsLoading]);

  useEffect(() => {
    if (mobileVersion || tabletVersion) {
      setDesktopExperienceModal(true);
    }
  }, [mobileVersion, tabletVersion]);

  return (
    <AppHelperContextProvider
      setLightBoxState={setLightBoxState}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
    >
      <Helmet
        title="humanoid research lab"
        meta={[
          {
            name: "description",
            content: "humanoid research lab",
          },
          {
            property: "og:title",
            content: "humanoid research lab",
          },
          {
            property: "og:description",
            content: "humanoid research lab",
          },
          {
            property: "og:image",
            content: "https://humanoidresearchlab.com/images/og-image.png",
          },
        ]}
        defer={false}
      />
      <BodyClassName className={scrollDisable ? "scroll-disabled" : ""}>
        <PreventAutoSelectOnScrollWrapper>
          <div id="cursor" />
          {children}
          <LightBox files={lightBoxState} />
          <DesktopExperienceModal
            desktopExperienceModal={desktopExperienceModal}
            setDesktopExperienceModal={setDesktopExperienceModal}
          />
        </PreventAutoSelectOnScrollWrapper>
      </BodyClassName>
    </AppHelperContextProvider>
  );
};

export default PageWrapper;
