import styled from "@emotion/styled";
import "animate.css";
import React, { Dispatch, FC, SetStateAction } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "../ui/FlexBox";
import { Overlay } from "../ui/Overlay";
import Spacer from "../ui/Spacer";

const Wrapper = styled(FlexBox)({
  position: "fixed",
  padding: rem(90),
  zIndex: 5,
  top: 0,
  left: 0,
  fontSize: rem(22),
  color: theme.color.whiteColor,
});

const Note = styled.p({
  fontSize: rem(28),
  textAlign: "center",
});

type DesktopExperienceModalT = {
  desktopExperienceModal: boolean;
  setDesktopExperienceModal: Dispatch<SetStateAction<boolean>>;
};

const DesktopExperienceModal: FC<DesktopExperienceModalT> = ({
  desktopExperienceModal,
  setDesktopExperienceModal,
}) => {
  if (!desktopExperienceModal) {
    return null;
  }

  return (
    <Wrapper fullHeight={true} fullWidth={true} style={{}}>
      <Overlay />
      <FlexBox flexDirection="column">
        <Note>
          {
            "For the full experience, we would like to recommend using a bigger resolution."
          }
        </Note>
        <FlexBox>
          <FlexBox
            style={{ color: theme.color.primaryColor }}
            onClick={() => window.location.reload()}
          >
            {"Reload"}
          </FlexBox>
          <Spacer size={42} />
          <FlexBox
            style={{ color: theme.color.greyColor }}
            onClick={() => setDesktopExperienceModal(false)}
          >
            {"Enter anyway"}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Wrapper>
  );
};

export default DesktopExperienceModal;
