import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem } from "../styling/theme";

type SpacerT = {
  size?: number;
  direction?: "horizontal" | "vertical";
};

const StyledSpacer = styled.div<{
  size: number;
  direction: "horizontal" | "vertical";
}>(({ size, direction }) =>
  direction === "horizontal"
    ? {
        paddingRight: rem(size),
        cursor: "inherit",
      }
    : {
        paddingBottom: rem(size),
        cursor: "inherit",
      }
);

const Spacer: FC<SpacerT> = ({ size = 16, direction = "horizontal" }) => (
  <StyledSpacer direction={direction} size={size} />
);

export default Spacer;
